import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AsyncSelect from 'react-select/async'
import { ajaxCall } from '../../../utils'
import _ from 'lodash'

import {} from '../../../actions'

const styles = (theme) => ({
  root: {
    margin: '-12px 0 !important',
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1,
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -6px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (theme) => ({
  control: (provided, state) => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: state.isFocused ? 2 : 1,
    borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
    borderRadius: 4,
    backgroundColor: 'transparent',
    margin: 0,
    minHeight: 41,
    padding: '0 14px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.select.inputControlBorder,
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    backgroundColor: theme.palette.select.inputControlMenuListBackground,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    color: theme.palette.text.primary
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    fontSize: '0.9285714285714286rem',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: theme.palette.select.inputControlMenuListBackground,
    color: theme.palette.select.text,
    cursor: 'pointer',
    '&:active': {
      backgroundColor: theme.palette.select.inputControlElementSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.select.inputControlElementHover,
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: '.5rem 0 0',
    flexGrow: 1,
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.select.multiValueListElementBackground,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    flexGrow: 1,
    color: theme.palette.text.primary
  }),
  input: (provided) => ({
    ...provided,
    color: theme.palette.text.primary
  })
})

class AsyncSelectField extends React.Component {
  state = {
    focused: false,
    selectedValue: null
  }

  filterOptions = (options, inputValue) => {
    return options ? options.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    ) : []
  }

  promiseOptions = inputValue => {
    const { field } = this.props

    return new Promise(resolve => {
      ajaxCall({
        config: {
          url: field.options,
          method: 'get',
          params: {
            query: inputValue
          }
        },
        success: (res) => {
          resolve(this.filterOptions(res.data.data, inputValue))
        },
        error: (res) => {
          // TODO
        },
        activeLoading: false
      })
    })
  }

  render() {
    const { field, classes, onChange, noOptionsMessage, index, theme} = this.props

    const { focused, selectedValue } = this.state

    const activeLabel = selectedValue ? (_.isArray(selectedValue) ? selectedValue.length > 0 || focused : true) : focused

    const labelActive = (activeLabel ? ' ' + classes.labelActive : '')

    const props = {}

    if (noOptionsMessage) {
      props.noOptionsMessage = () => noOptionsMessage.noResults
    }

    return (
      <FormControl fullWidth={true} error={field.error ? true : false} className={classes.root}>
        <InputLabel variant='outlined' className={[
          classes.label,
          labelActive,
          (field.classes || {}).label
        ].join(' ')}>{field.label}</InputLabel>
        <AsyncSelect
          defaultOptions
          defaultValue={field.value}
          loadOptions={this.promiseOptions}
          styles={customStyles(theme)}
          onChange={values => {
            const selectedValue = _.isArray(values) ? values.map(value => {
              return value.value
            }) : values.value || null

            this.setState({
              selectedValue
            })

            return onChange(selectedValue, index, field.name)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          isMulti={field.isMulti || false}
          placeholder={null}
          {...props}
          laceholder='' />
      </FormControl>
    )
  }
}

AsyncSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AsyncSelectField)))
