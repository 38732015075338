import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from '../../routes'
import AuthRoutes from '../../routes/Auth'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import Message from '../../components/Message'
import Confirm from '../../components/Confirm'
import TitleBlock from '../../components/TitleBlock'
import Print from '../../components/Print'
import HasUpdates from '../../components/HasUpdates'
import Chatbot from '../../components/Chatbot'
import '../../components/FontAwesome'
import ResetPassword from '../ResetPassword'
import OtpCheck from '../OtpCheck'
import theme from './theme-config.js'

import { confirmActions, appActions, paginationConfigActions } from '../../actions'

const styles = () => ({
  root: {
    display: 'flex',
    minWidth: 1200,
    width: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    paddingLeft: theme.themeConfig.appVars.sidebarWidth.main,
    paddingTop: 64,
    transition: 'padding-left .3s ease-in-out',
    fontSize: 13
  },
  open: {
    paddingLeft: theme.themeConfig.appVars.sidebarWidth.open,
  },
  container: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
  },
  appAndFooterContainer: {
    maxHeight: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  routerContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 60,
  },
  routContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
})

class App extends React.Component {
  state = {
    reloading: false,
    themePreference: 'system',
    fontSizeScale: 1.2
  }

  componentDidMount = () => {
    const { onInitApp, onZoomChange } = this.props

    onInitApp()

    const settings = this.loadSettings()

    this.setState(settings, () => {
      onZoomChange(this.mapFontSizeToZoom(settings.fontSizeScale))
    })

    if (settings.themePreference === 'system') {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.handleSystemThemeChange)
    }
  }

  loadSettings = () => {
    return {
      themePreference: localStorage.getItem('themePreference') || 'system',
      fontSizeScale: parseFloat(localStorage.getItem('fontSizeScale')) || 1.2
    }
  }

  updateSettings = (key, value) => {
    this.setState({ [key]: value }, () => {
      localStorage.setItem(key, value.toString())
      
      if (key === 'fontSizeScale') {
        this.props.onZoomChange(this.mapFontSizeToZoom(value))
      }
    })
  }

  updateFontSizeScale = (newScale) => {
    this.updateSettings('fontSizeScale', newScale)
  }

  updateThemePreference = (preference) => {
    this.updateSettings('themePreference', preference)
  }

  mapFontSizeToZoom = (fontSizeScale) => {
    const zoomMap = {
      0.8: 0.80,
      1.0: 0.85,
      1.2: 0.9,
      1.4: 0.95,
      1.6: 1.0
    }

    return zoomMap[parseFloat(fontSizeScale.toFixed(1))] || 1.0
  }

  handleSystemThemeChange = () => {
    if (this.state.themePreference === 'system') {
      this.forceUpdate()
    }
  }

  componentDidUpdate = (prevProps) => {
    const { appConfig: prevAppConfig, location: prevLocation } = prevProps

    const { appConfig, location, onHandleOpen, onInitPaginationConfig, history } = this.props

    if (prevLocation.pathname !== location.pathname) {
      onInitPaginationConfig()
    }

    const { selectedDb: prevSelectedDb } = prevAppConfig

    const { selectedDb } = appConfig

    if (prevSelectedDb && prevSelectedDb.value !== selectedDb.value) {
      history.push('/')

      this.setState({
        reloading: true
      }, () => {
        setTimeout(() => {
          this.setState({
            reloading: false
          })
        }, 100)
      })
    }

    const { password: prevPassword = {} } = ((prevProps.appConfig || {}).userConfig || {}).user || {}

    const { password = {} } = ((appConfig || {}).userConfig || {}).user || {}

    if (password.expiring !== prevPassword.expiring && password.expiring) {
      onHandleOpen(password.confirm, this.testConfirm, [])
    }
  }

  testConfirm = () => {
    const { history, appConfig } = this.props

    history.push(appConfig.appPrefix + '/user/profile')
  }

  toggleTheme = () => {
    this.updateThemePreference(!this.state.darkMode)
  }

  render() {
    const { classes, appConfig, sidebarConfig, sidebarIsOpen, dbList, hasUpdates, hasSoftwareUpdates, updater } = this.props

    const { reloading, themePreference, fontSizeScale } = this.state

    const darkMode = themePreference === 'system' ? window.matchMedia('(prefers-color-scheme: dark)').matches : themePreference === 'dark'

    const { password } = ((appConfig || {}).userConfig || {}).user || {}

    if (!appConfig || (appConfig.isLogedIn && !password)) {
      return ''
    }

    const is2F = appConfig.isLogedIn && password && password.is2F

    const isResetPassword = appConfig.isLogedIn && (!password || password.expired || password.firstAccess)

    const customTheme = createMuiTheme(theme.getThemeConfig(darkMode, fontSizeScale))

    return (
      <MuiThemeProvider theme={customTheme}>
        <CssBaseline />
        <Confirm />
        <Message />
        <Loading />
        {is2F ? (
          <OtpCheck />
        ) : isResetPassword ? (
          <ResetPassword />
        ) : (
          <React.Fragment>
            {appConfig.userConfig ? (
              <HasUpdates hasUpdates={hasUpdates} hasSoftwareUpdates={hasSoftwareUpdates} platform={appConfig.userConfig.platform} />
            ) : null}
            {(!appConfig || !appConfig.isLogedIn) ? (
              <AuthRoutes />
            ) : (!dbList || !sidebarConfig || reloading ? (
              <Loading forceLoading={true} />
            ) : (
              <div className={classes.root + (sidebarIsOpen ? ' ' + classes.open : '')}>
                <Print />
                <Sidebar />
                <div className={classes.container}>
                  <Header dbList={dbList} updater={updater} darkMode={darkMode} themePreference={this.state.themePreference} onUpdateThemePreference={this.updateThemePreference} fontSizeScale={this.state.fontSizeScale} onUpdateFontSizeScale={this.updateFontSizeScale}/>
                  {appConfig.userConfig.hasChatBot ? (
                    <Chatbot/>
                  ) : null}
                  <div className={classes.appAndFooterContainer}>
                    <div className={classes.routerContainer}>
                      <TitleBlock sidebarIsOpen={sidebarIsOpen} />
                      <div className={classes.routContainer}>
                        <Routes sidebarIsOpen={sidebarIsOpen} />
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onInitApp: () => {
    dispatch(appActions.initApp())
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onInitPaginationConfig: () => {
    dispatch(paginationConfigActions.initPaginationConfig)
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(App)))
