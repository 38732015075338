import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import BaseField from './BaseField'
import _ from 'lodash'
import { getPaginationConfig } from '../../../utils/pagination'

import {
  appActions,
  inlineFormActions,
  listPageActions,
  listPageAjaxActions,
  printActions
} from '../../../actions'

const styles = () => ({
  root: {
    padding: '0 1rem 1rem',
    display: 'flex',
    flexGrow: 1
  },
  label: {
    paddingRight: '1rem',
    alignSelf: 'center'
  },
  container: {
    flexGrow: 1
  }
})

class Divider extends React.Component {
  state = {
    fieldProps: {}
  }

  onChange = (value, index, fieldName) => {
    return this.props.onAddValueToField(value, index, fieldName)
  }

  onSubmit = (type, index, url, field) => {
    const { inlineForm } = this.props

    const defaultInlineForm = inlineForm[index] || {}

    this.props.action.fields.forEach(field => {
      if (!field.preventDefault && field.value && field.name && !defaultInlineForm[field.name]) {
        defaultInlineForm[field.name] = field.value.value === 0 ? field.value.value : field.value.value || field.value
      }
    })

    switch (type) {
      case 'print':
        return this.props.onPrintPost(field.action, defaultInlineForm)
      default:
        switch (url) {
          case 'resetPage':
            return this.updatePage(index, true)
          case 'updatePage':
            return this.updatePage(index)
          default:
            return this.submitForm(type, index, url, true)
        }
    }
  }

  updatePage = (index, reset) => {
    const { match, pageType, pageTypes, inlineForm } = this.props

    if (reset) {
      this.props.onResetParams()

      this.props.onListPagesGet(pageType, pageTypes, match.params)

      return this.props.onListPageGet(pageType, match.params)
    }

    const params = {
      ...match.params,
      ...(reset ? {} : inlineForm[index] || {})
    }

    return this.props.onListPagesGet(pageType, pageTypes, params)
  }

  submitForm = (type, index, url, reset) => {
    this.props.onGetFilteredItems()

    setTimeout(() => {
      let { filteredItems } = this.props

      filteredItems = filteredItems || []

      const { selectedElements, inlineForm } = this.props

      const ids = type === 'filtered' ? filteredItems.map(item => item.id) : Object.keys(selectedElements)

      this.props.onSubmitForm({
        ids,
        type,
        ...(reset ? inlineForm[index] : {})
      }, url)
    }, 200)
  }

  updateRelated = (field, sourceValue) => {
    if (!field.updateRelated) {
      return
    }

    Object.entries(field.updateRelated).forEach(([type, relateds]) => {
      switch (type) {
        case 'addPropToField':
          const addPropToField = (relateds, index = 0) => {
            const related = relateds[index]

            if (!related) {
              return
            }

            const fieldProps = _.cloneDeep(this.state.fieldProps)

            const currentProp = {}

            currentProp[related.prop] = sourceValue.forRelated[related.valueProp]

            fieldProps[related.fieldName] = {
              ...fieldProps[related.fieldName],
              ...currentProp
            }

            return this.setState({
              fieldProps
            }, () => {
              return addPropToField(relateds, index + 1)
            })
          }

          return addPropToField(relateds)
        case 'activateButton':
          const activateButton = (relateds, index = 0) => {
            const related = relateds[index]

            if (!related) {
              return
            }

            this.props.onUpdateActiveElement(related.name, related.values.includes(sourceValue.value))

            return activateButton(relateds, index + 1)
          }

          return activateButton(relateds)
        default:

      }
    })
  }

  render() {
    const {
      classes,
      action,
      enabled,
      index,
      pageType,
      paginationConfig: propPaginationConfig = {}
    } = this.props

    const paginationConfig = getPaginationConfig(propPaginationConfig)

    const {
      selectCache
    } = paginationConfig

    return (
      <div className={classes.root}>
        {action.title ? (
          <div className={classes.label}>{action.title}</div>
        ) : null}
        <div className={classes.container}>
          <Grid container spacing={24}>
            {action.fields.map((field, fieldIndex) => {
              const stateProps = field.name ? this.state.fieldProps[field.name] : {}

              if (field.name && ((selectCache || {})[field.name])) {
                field.preventDefault = true

                field.value = (selectCache || {})[field.name]
              }

              return (
                <BaseField field={field} pageType={pageType} key={fieldIndex} enabled={enabled} index={index} onChange={(value, index, fieldName, sourceValue) => {
                  this.updateRelated(field, sourceValue)

                  return this.onChange(value, index, fieldName)
                }} {...stateProps} onSubmit={(type, index, actionType, theField) => this.onSubmit(type, index, actionType || action.action, theField || field)} />
              )
            })}
          </Grid>
        </div>
      </div>
    )
  }
}

Divider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => {
  return {
    ...state.appReducer,
    ...state['listPage' + (props.fromAjaxPage ? 'Ajax' : '') + 'Reducer'],
    ...state.inlineFormReducer,
    ...state.paginationConfigReducer
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const tmpListPageActions = props.fromAjaxPage ? listPageAjaxActions : listPageActions

  return {
    onListPagesGet: (pageType, pageTypes, params) => {
      dispatch(tmpListPageActions.listPagesGet(pageType, pageTypes, params, false))
    },
    onListPageGet: (pageType, params) => {
      dispatch(tmpListPageActions.listPageGet(pageType, params))
    },
    onAddValueToField: (value, index, fieldName) => {
      dispatch(inlineFormActions.addValueToField(value, index, fieldName))
    },
    onSubmitForm: (params = {}, url) => {
      dispatch(inlineFormActions.submitFormPost(params, url))
    },
    onResetParams: () => {
      dispatch(inlineFormActions.resetParams())
    },
    onGetFilteredItems: () => {
      dispatch(appActions.getFilteredItems())
    },
    onPrintPost: (url, params) => {
      dispatch(printActions.printPost(url, params))
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Divider)))
