import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import {
  messageActions
} from '../../actions'

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.white,
  },
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    aligntItems: 'center,'
  },
  close: {
    color: theme.palette.text.white
  }
})

function TransitionUp(props) {
  return <Slide {...props} direction="up" />
}

class Message extends React.Component {
  render() {
    const { classes, openMessage, messageConfig } = this.props

    const icons = {
      success: 'check_circle',
      warning: 'warning',
      error: 'error',
      info: 'info'
    }

    switch (messageConfig.messageType) {
      case 'modal':
        return (
          <Dialog
            open={openMessage}
            TransitionComponent={TransitionUp}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description' >
            <DialogTitle id='alert-dialog-slide-title'>
              {messageConfig.title || 'Info'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-slide-description' dangerouslySetInnerHTML={{__html: messageConfig.message}}>
              </DialogContentText>
            </DialogContent>
            {messageConfig.confirm ? (
              <DialogActions>
                <Button onClick={this.props.onCloseMessage} color="primary" autoFocus>
                  {messageConfig.confirm}
                </Button>
              </DialogActions>
            ) : ''}

          </Dialog>
        )
      default:
        return (
          <Snackbar
            open={openMessage}
            onClose={this.props.onCloseMessage}
            TransitionComponent={TransitionUp} >
            <SnackbarContent
              className={classes.root + ' ' + classes[messageConfig.type]}
              message={
                <span id="client-snackbar" className={classes.message}>
                  <Icon className={classes.icon}>{icons[messageConfig.type]}</Icon>
                  {messageConfig.message}
                </span>
              }
              action={[
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  className={classes.close}
                  onClick={this.props.onCloseMessage} >
                  <Icon>close</Icon>
                </IconButton>
              ]} />
          </Snackbar>
        )
    }
  }
}

Message.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.messageReducer })

const mapDispatchToProps = dispatch => ({
  onCloseMessage: () => {
    dispatch(messageActions.closeMessage())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Message)))
