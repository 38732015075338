import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    padding: '0 1rem',
    marginTop: '1rem',
    marginBottom: '-1rem',
  },
  tabsAction: {
    padding: '0 .5rem',
  },
  tabAction: {
    minWidth: 0,
    marginTop: '.5rem',
    '&:hover': {
      backgroundColor: theme.palette.background.activeTabs,
      borderRadius: '5px 5px 0 0',
      color: theme.palette.text.sidebar + '!important'
    }
  },
  tabActionLabel: {
    padding: '0 12px'
  },
  tabActionActive: {
    fontWeight: 700,
    color: theme.palette.text.sidebar + '!important',
    backgroundColor: theme.palette.background.activeTabs,
    borderRadius: '5px 5px 0 0',
    '&:hover': {
      backgroundColor: theme.palette.background.activeTabsHover,
    }
  }
})

class TabActons extends React.Component {
  render() {
    const { actions, classes, appConfig } = this.props

    return (
      <Tabs
        value={actions.findIndex(action => {
          return action.active
        })}
        variant='scrollable'
        scrollButtons='off'
        indicatorColor='primary'
        textColor='primary'
        classes={{
          root: classes.root,
          flexContainer: classes.tabsAction
        }} >
        {actions.map((action, index) => {
          return (
            <Tab
              classes={{
                root: classes.tabAction + (action.active ? ' ' + classes.tabActionActive : ''),
                labelContainer: classes.tabActionLabel
              }}
              label={action.label}
              key={index}
              component={NavLink}
              to={appConfig.appPrefix + action.action} />
          )
        })}
      </Tabs>
    )
  }
}

TabActons.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TabActons)))
