import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AsyncSelectField from './AsyncSelect'
import _ from 'lodash'

import {
  inlineFormActions
} from '../../../actions'

const styles = (theme) => ({
  root: {
    margin: '-12px 0 !important',
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 3,
      top: '50%',
      marginTop: -2,
      left: -5,
      right: -5,
      zIndex: -1,
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, -6px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (theme) => {
  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover,
      borderRadius: 4,
      backgroundColor: state.isDisabled ? theme.palette.background.disabled : 'transparent',
      opacity: state.isDisabled ? '.8' : 1,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: theme.palette.select.inputControlBorder,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: '.5rem 0 0',
      flexGrow: 1,
      width: '100%',
      color: theme.palette.text.primary
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      flexGrow: 1,
      color: theme.palette.text.primary
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      flexGrow: 1,
      color: theme.palette.text.primary
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
      color: theme.palette.text.primary
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      color: theme.palette.select.text,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: theme.palette.select.inputControlElementSelected,
      },
      '&:hover': {
        backgroundColor: theme.palette.select.inputControlElementHover,
      }
    }),
    input: (provided) => ({
      ...provided,
      color: theme.palette.text.primary
    })
  }
}

class SelectField extends React.Component {
  state = {
    focused: false,
    selectedValue: ''
  }

  componentDidMount() {
    const {
      value,
      values,
      options,
      isMulti
    } = this.props.field

    let selectedValue = (values || value) || ''

    if (typeof options !== 'string') {
      const tmpValues = values || [value]

      selectedValue = []

      tmpValues.forEach(tmpValue => {
        const tmpOption = options.find(option => {
          const currentValue = tmpValue ? tmpValue.value || tmpValue : tmpValue

          return ('' + option.value) === ('' + currentValue)
        })

        if (tmpOption) {
          selectedValue.push(tmpOption)
        }
      })
    }

    this.setState({
      selectedValue: isMulti ? selectedValue : selectedValue[0],
      mounted: true
    })
  }

  onChange = (values) => {
    const {
      index,
      onChange,
      field,
      pageType
    } = this.props

    const selectedValue = _.isArray(values) ? values.map(value => {
      return value.value
    }) : (values.value || Number.isInteger(values.value) ? '' + values.value : null)

    this.setState({
      selectedValue
    })

    this.props.onCacheSelect(pageType, selectedValue, field.name)

    onChange(selectedValue, index, field.name, values)
  }

  render() {
    if (!this.state.mounted) {
      return ''
    }

    const {
      field,
      classes,
      noOptionsMessage,
      selectClasses,
      theme
    } = this.props

    const newClasses = {
      ...classes,
      ...selectClasses
    }

    if (typeof field.options === 'string') {
      return <AsyncSelectField {...this.props} />
    }

    const options = typeof field.options !== 'string' ? field.options : []

    const { focused, selectedValue } = this.state

    const activeLabel = selectedValue ? (_.isArray(selectedValue) ? selectedValue.length > 0 || focused : true) : focused

    const labelActive = (activeLabel ? ' ' + newClasses.labelActive : '')

    const props = field.selectProps || {}

    if (noOptionsMessage) {
      props.noOptionsMessage = () => noOptionsMessage.noResults
    }

    return (
      <FormControl fullWidth={true} className={newClasses.root}>
        {field.label ? (
          <InputLabel variant='outlined' className={[
            classes.label,
            labelActive,
            (field.classes || {}).label,
            activeLabel ? (field.classes || {}).labelActive : ''
          ].join(' ')}>{field.label}</InputLabel>
        ) : ''}
        <Select
          variant='outlined'
          defaultValue={selectedValue}
          styles={{
            ...customStyles(theme),
            ...(field.classes || {})
          }}
          isDisabled={field.disabled}
          options={options}
          inputId={_.uniqueId()}
          placeholder={field.placeholder || false}
          isMulti={field.isMulti ? true : false}
          isClearable={field.isClearable ? true : false}
          onChange={this.onChange}
          {...props}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          noOptionsMessage={() => noOptionsMessage ? noOptionsMessage.noResults : null} />
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  onCacheSelect: (pageType, selectedValue, fieldName) => {
    dispatch(inlineFormActions.cacheSelect(pageType, selectedValue, fieldName))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectField)))
