import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MIcon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '../../components/Icon'
import Update from '../../components/Update'

import {
  appActions,
  updateActions
} from '../../actions'

const styles = theme => ({
  heroContent: {
    maxWidth: 700,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.success.main,
    marginBottom: '1rem',
    display: 'flex'
  },
  warning: {
    color: theme.palette.warning.main,
    marginBottom: '1rem',
    display: 'flex'
  },
  message: {
    marginBottom: '4rem'
  },
  button: {
    marginBottom: '3rem'
  },
  text: {
    fontSize: '1.5rem'
  },
  textIcon: {
    marginRight: '.5rem',
    display: 'block'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  failuresAlert: {
    backgroundColor: theme.palette.warning.main,
    padding: '1rem',
    marginTop: '3rem',
    marginBottom: '1rem'
  },
  failuresDb: {
    textAlign: 'left'
  },
  failuresDbTitle: {
    fontSize: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginTop: '2rem',
    marginBottom: '.5rem',
    display: 'block'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginRight: '1rem',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  failureUids: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  failuresContainer: {
    backgroundColor: theme.palette.background.default
  }
})

class UpdateContainer extends React.Component {
  state = {}

  componentDidMount = () => {
    this.props.onChangeTitleBlock({
      title: 'Sincronizzazione',
      breadcrumbs: [
        {
          text: 'Sincronizzazione'
        }
      ]
    })
  }

  componentDidUpdate = (prevProp, prevState) => {
    if (this.state.disabled && !this.props.updating) {
      this.setState({
        disabled: false
      })
    }
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    })
  }

  render() {
    const { classes, hasUpdates, forceSoftwareUpdates, updating, failures } = this.props

    const { disabled, expanded } = this.state

    if (forceSoftwareUpdates) {
      return (
        <div className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            <Icon className={classes.warning}>download</Icon>
            Per poter sincronizzare, installa l'ultima versione di Achille©
          </Typography>

          <Button size='large' color='primary' className={classes.button} variant='contained' onClick={this.props.onInstallSoftware}>
            <MIcon>get_app</MIcon>
            Aggiorna ora
          </Button>
        </div>
      )
    }

    return (
      <div className={classes.heroContent}>
        {updating ? (
          <Update />
        ) : null}
        {hasUpdates ? (
          <div className={classes.message}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              <Icon className={classes.warning}>sync</Icon>
              Ci sono dati da sincronizzare
            </Typography>
          </div>
        ) : (
          <div className={classes.message}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              <Icon className={classes.icon}>thumbs-up</Icon>
              Il sistema è allineato
            </Typography>
          </div>
        )}
        <Button size='large' disabled={disabled} color='primary' className={classes.button} variant='contained' onClick={() => {
          this.setState({
            disabled: true
          })

          return this.props.onUpdate()
        }}>
          <MIcon>sync</MIcon>
          Sincronizza
        </Button>
        <div className={classes.text}>
          <div className={classes.flex}><MIcon className={classes.textIcon}>warning</MIcon> <strong>ATTENZIONE!</strong></div>
          <div>Durante la sincronizzazione non sarà possibile utilizzare il sistema e si raccomanda di non chiudere Achille©</div>
        </div>
        {(failures || []).length ? (
          <div className={classes.failures}>
            <div className={classes.failuresAlert}>
              <div className={classes.flex}>
                <MIcon className={classes.textIcon}>warning</MIcon>
                <strong>ATTENZIONE!</strong>
              </div>
              <div>Ci sono dei dati che non è stato possibile sincronizzare</div>
            </div>
            {failures.map((failuresDb, dbIndex) => {
              let uidsLength = 0

              failuresDb.models.forEach(item => {
                uidsLength += item.uids.length
              })

              if (!uidsLength) {
                return null
              }

              const dbKey = ['failure', dbIndex].join('-')

              return (
                <div className={classes.failuresDb} key={dbKey}>
                  {failuresDb.db.url ? (
                    <a className={classes.failuresDbTitle} href={failuresDb.db.url} target='_blank' rel='noopener noreferrer'>
                      {failuresDb.db.name}
                    </a>
                  ) : (
                    <div className={classes.failuresDbTitle}>
                      {failuresDb.db.name}
                    </div>
                  )}
                  {failuresDb.models.map((model, modelIndex) => {
                    const modelKey = [dbKey, modelIndex].join('-')

                    if (!model.uids.length) {
                      return null
                    }

                    return (
                      <ExpansionPanel expanded={expanded === modelKey} key={modelKey} onChange={this.handleChange(modelKey)}>
                        <ExpansionPanelSummary expandIcon={<MIcon>expand_more</MIcon>} aria-controls='panel1bh-content' id='panel1bh-header'>
                          <Typography className={classes.heading}>{model.name}</Typography>
                          <Typography className={classes.secondaryHeading}>({model.uids.length})</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.failuresContainer}>
                          <ul className={classes.failureUids}>
                            {model.uids.map((uid, uidIndex) => {
                              const uidKey = [modelKey, uidIndex].join('-')

                              return (
                                <li key={uidKey} className={classes.failureUid}>{uid}</li>
                              )
                            })}
                          </ul>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  })}
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }
}

UpdateContainer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.updateReducer })

const mapDispatchToProps = dispatch => ({
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onUpdate: () => {
    dispatch(updateActions.update())
  },
  onInstallSoftware: () => {
    dispatch(appActions.toggleUpdater(true))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UpdateContainer)))
