import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import _ from 'lodash'

import {} from '../../../actions'

const styles = (theme) => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -7,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 3,
      top: '50%',
      marginTop: -1,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, 0px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (config, isColored, theme) => {
  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : (state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover),
      borderRadius: 4,
      backgroundColor: state.isDisabled ? theme.palette.background.disabled : (isColored ? theme.palette.background.colored : theme.palette.background.controlSelect),
      opacity: 1,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : theme.palette.select.inputControlBorder,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: '.5rem 0 0',
      flexGrow: 1,
      width: '100%',
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      flexGrow: 1,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      flexGrow: 1,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      color: theme.palette.select.text,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: theme.palette.select.inputControlElementSelected,
      },
      '&:hover': {
        backgroundColor: theme.palette.select.inputControlElementHover,
      }
    }),
    input: (provided) => ({
      ...provided,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
    })
  }
}

class SelectField extends React.Component {
  state = {
    focused: false,
    selectedValue: ''
  }

  render() {
    const { action, classes, history, theme } = this.props

    const { options } = action

    const { focused, selectedValue } = this.state

    const labelIsActive = focused || selectedValue ? ' ' + classes.labelActive : ''

    return (
      <FormControl fullWidth={true} className={classes.root}>
        {action.label ? (
          <InputLabel variant='outlined' className={classes.label + labelIsActive}>{action.label}</InputLabel>
        ) : ''}
        <Select
          variant='outlined'
          styles={customStyles({}, false, theme)}
          options={options}
          value={selectedValue || ''}
          inputId={_.uniqueId()}
          placeholder={action.placeholder || false}
          isClearable={true}
          onChange={(value) => {
            this.setState({
              selectedValue: value
            })

            return history.push(action.action + value.value)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})} />
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectField)))
