import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import Icon from '../Icon'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginBottom: 0,
    marginTop: 16,
  },
  rootSelect: {
    minWidth: 200,
  },
  input: {
    borderRadius: '0 0 4px 4px',
    padding: '.5rem 1rem',
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -6,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 6,
      top: '50%',
      marginTop: -3,
      left: -5,
      right: -5,
      zIndex: -1
    }
  },
  labelActive: {
    backgroundColor: theme.palette.background.muiPaper,
    transform: 'translate(14px, 0px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  },
  comboSelect: {
    display: 'flex',
  },
  selectContainer: {
    flexGrow: 1,
  },
  selectIconButton: {
    justifySelf: 'flex-start',
    padding: 5
  },
  selectIcon: {
    width: 26,
    height: 26
  }
})

const customStyles = (config, theme) => {
  const newCustomStyles = config.customStyles || {}

  const defaultCustomStyles = {
    control: (provided, state) => ({}),
    menu: (provided, state) => ({}),
    dropdownIndicator: (provided, state) => ({}),
    singleValue: (provided, state) => ({}),
    valueContainer: (provided, state) => ({}),
    indicatorSeparator: (provided, state) => ({}),
    option: (provided, state) => ({}),
    input: (provided, state) => ({}),
    ...newCustomStyles
  }

  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : (state.isFocused ? theme.palette.select.inputControlBorder : theme.palette.select.inputControlBorderHover),
      borderRadius: '4px 4px 0 0',
      backgroundColor: theme.palette.background.controlSelect,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? theme.palette.error.main : theme.palette.select.inputControlBorder,
      },
      ...defaultCustomStyles.control(provided, state)
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 100,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      ...defaultCustomStyles.menu(provided, state)
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
      ...defaultCustomStyles.dropdownIndicator(provided, state)
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: 0,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.singleValue(provided, state),
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.valueContainer(provided, state),
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
      ...defaultCustomStyles.indicatorSeparator(provided, state),
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: theme.palette.select.inputControlMenuListBackground,
      color: theme.palette.select.text,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: theme.palette.select.inputControlElementSelected,
      },
      '&:hover': {
        backgroundColor: theme.palette.select.inputControlElementHover,
      },
      ...defaultCustomStyles.option(provided, state)
    }),
    input: (provided, state) => ({
      ...provided,
      color: config.error ? theme.palette.error.main : theme.palette.text.primary,
      ...defaultCustomStyles.input(provided, state)
    })
  }
}

class ComboText extends React.Component {
  state = {
    selectValue: ''
  }

  componentDidMount = () => {
    return this.setState({
      value: this.props.cell.value
    })
  }

  onChange = (value) => {
    const { onChange, fieldPath } = this.props

    this.setState({
      value
    }, () => {
      return onChange(value, fieldPath)
    })
  }

  render() {
    const { classes, cell, isActive, readonly, theme } = this.props

    const {
      options,
      label,
      inputType,
      name,
      multiline,
      placeholders = {},
      addMultiple,
    } = cell

    const { value, selectValue } = this.state

    const labelActive = ' ' + classes.labelActive

    return (
      <div className={classes.root}>
        <FormControl fullWidth={true} className={classes.rootSelect}>
          {label ? (
            <InputLabel variant='outlined' className={classes.label + labelActive}>{label}</InputLabel>
          ) : ''}
          {addMultiple ? (
            <div className={classes.comboSelect}>
              <div className={classes.selectContainer}>
                <Select
                  variant='outlined'
                  options={options}
                  styles={customStyles({
                    customStyles
                  }, theme)}
                  inputId={_.uniqueId()}
                  placeholder={placeholders.select || false}
                  isClearable={true}
                  isDisabled={readonly || !isActive}
                  onChange={(selectValue) => {
                    return this.setState({
                      selectValue
                    })
                  }} />
              </div>
              <IconButton className={classes.selectIconButton} onClick={() => {
                if (selectValue) {
                  return this.onChange((value ? value + '\n' : '') + selectValue.value)
                }
              }}>
                <Icon className={classes.selectIcon}>circle-arrow-down</Icon>
              </IconButton>
            </div>
          ) : (
            <Select
              variant='outlined'
              options={options}
              value=''
              styles={customStyles({
                customStyles
              }, theme)}
              inputId={_.uniqueId()}
              placeholder={placeholders.select || false}
              isClearable={true}
              isDisabled={readonly || !isActive}
              onChange={(value) => {
                return this.onChange(value.label)
              }} />
          )}
        </FormControl>
        <TextField
          variant='outlined'
          fullWidth={true}
          disabled={!isActive}
          placeholder={placeholders.text || false}
          multiline={multiline}
          rows={5}
          type={inputType}
          name={name}
          value={value || ''}
          onChange={(event) => {
            return this.onChange(event.target.value)
          }}
          InputProps={{
            classes: {
              root: classes.input,
              notchedOutline: classes.input
            },
            inputProps: {
              readOnly: readonly
            }
          }} />
      </div>
    )
  }
}

ComboText.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ComboText)))
